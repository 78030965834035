@import '../main.scss';


.major {
	display: flex;
  	flex-direction: row;

	max-width: 50%;
	margin: auto;
	padding-top: 2rem;

	align-items: flex-end;

	img {
		width: 10vw;
		height: auto;
		border-radius: 0.1rem;
		filter: drop-shadow(-.1rem .1rem 0.2rem $light-lime);
	}

	h1 { 
		margin-left: 2rem;


	}
}

.container {
	display: grid;
	grid-template-columns: 1fr 2fr;
	display: flex;
  	flex-direction: column;
	max-width: 50%;
	margin: auto;
	padding-top: 2rem;
	justify-content: center;
	min-height: 100%;
	

	.right {
		order: 1;
	}
	
	.left {
		order: 2;
	}


	@media (min-width: 845px) {
		flex-direction: row;
	
		.right {
		  order: 2;
		}
	
		.left {
		  order: 1;
		}

	  }

	.left {
		justify-content: space-between;
		min-height: 100%;

		.skills {

			h2 {
				text-transform: uppercase;
				font-size: medium;
			}

			.skillCategory {

				span {
					font-size: small;
					padding-bottom: 0.5rem;
					border-bottom: 0.1rem solid $fresh-lemon;
					width: auto;
				}
				
				ul {
					list-style-type: none;
					padding-left: 1rem;
					// margin: 0.2rem;
				}
				
				li {
					font-size: small;
				}
			}
		}
	}

	.right {
		width: 100%;
		min-height: 100%;
		// grid-template-columns: 1fr 3fr;
		display: flex;
		flex-direction: column;
		justify-content: space-between;


		.codeFragment {
			// grid-column-start: 2;
			display: flex;
			justify-content: space-between;
			flex-direction: column;
			height: auto;
			margin-left: 13rem;
			// margin-top: -2rem;

			.topLeft {
				align-self: flex-start;
			}
			  
			.bottomRight {
			align-self: flex-start;
			margin-left: 5rem;
			margin-top: 2rem;
			}

		}

		

		.contact {
			display: flex;
			flex-direction: column;
			justify-content: flex-end;
			margin-top: 2rem;
			margin-bottom: 2rem;
			a {
				position: relative;
				text-decoration: none;
				color: inherit;
				display: inline-block;
				text-transform: lowercase;
				font-size: small;
				color: $mint;
				font-weight: bold;
				letter-spacing: 0.06rem;
				margin-top: 0.5rem;
				
				span {
					position: absolute;
					bottom: 0.4rem;
					left: 2rem;
					padding-bottom: 0.2rem;

				}
				span::after {
					content: '';
					position: absolute;
					width: 100%;
					transform: scaleX(0);
					height: 2px;
					bottom: 0;
					left: 0;
					background-color: $fresh-lemon;
					transform-origin: bottom right;
					transition: transform 0.25s ease-out;
				}
				  
				span:hover::after {
					transform: scaleX(1);
					transform-origin: bottom left;
				}
			}


		}
		
	}



	
}

@media (max-width: 844px) {

	.major {
		max-width: 80%;

		img {
			width: 20vw;
			height: auto;
			border-radius: 0.1rem;
			filter: drop-shadow(-.1rem .1rem 0.2rem $light-lime);
		}

	}

	.container {
		max-width: 80%;

		.right {
			.codeFragment {
				margin-left: 0;
			}
		}
	}

	.right {
	  h1 {
		font-size: x-large;
	  }
	}

  }

footer {
	margin-top: auto;
	width: 100%;
	text-align: right;
	
	.link {
		width: auto;
		color: $light-lime;
		font-size: large;
		animation: neonGlow 2s infinite;
		padding-bottom: 1rem;
		margin-right: 10%;

		cursor: pointer;
		text-decoration: none;
	}

	.link::after {
		content: '';
		width: 100%;
		transform: scaleX(0);
		height: 2px;
		background-color: $light-lime;
		transform-origin: bottom right;
		transition: transform 0.25s ease-out;
	}
	  
	.link:hover::after {
		transform: scaleX(1);
		transform-origin: bottom left;
		cursor: pointer;
	}
}