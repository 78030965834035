@import '../main.scss';

.container {
	// position: relative;
	display: flex;
	flex-direction: column;
	height: 100vh;
	width: 100vw;
}


footer {
	width: 100%;
	text-align: right;
	margin-right: 3vw;
	margin-bottom: 10vh;

	// position: sticky;
    // bottom: 0;
    // width: 100%;
    // text-align: right;
    // margin-right: 3vw;
	
	.link {
		width: auto;
		color: $light-lime;
		font-size: large;
		animation: neonGlow 2s infinite;
		padding-bottom: 1rem;
		margin-right: 10%;

		cursor: pointer;
		text-decoration: none;
	}

	.link::after {
		content: '';
		width: 100%;
		transform: scaleX(0);
		height: 2px;
		background-color: $light-lime;
		transform-origin: bottom right;
		transition: transform 0.25s ease-out;
	}
	  
	.link:hover::after {
		transform: scaleX(1);
		transform-origin: bottom left;
		cursor: pointer;
	}
}

