@import '../main.scss';

@keyframes slideUp {
    from {
        transform: translateY(0);
        opacity: 0;
    }

    to {
        transform: translateY(-30vh);
        opacity: 1;
    }
}

@keyframes slideUpper {
    from {
        transform: translateY(0);
        opacity: 0;
    }

    to {
        transform: translateY(-200px);
        opacity: 1;
    }
}

@keyframes slideDown {
    from {
        transform: translateY(-200px);
        opacity: 1;
    }

    to {
        transform: translateY(0);
        opacity: 0;
    }
}

@media (min-width: 845px) {

.container {
	display: flex;
    align-items: center;
    justify-content: center;
    height: 100vh;
    width: 100vw;
}
  
.timeline {
	animation: slideUp 1s ease-in 0s 1 forwards;
	display: flex;
	flex-direction: column;
	width: 100%;
	height: 50%;
	border-bottom: 0.1rem solid $light-lime;

	transition: top 1s ease-in-out;
	transition-delay: 1.5s;
	justify-content: center;
	align-items: center;
	position: relative;
	top: 30vh;

	.events {
		position: absolute;
		bottom: 0;
		left: 50%;
		transform: translate(-50%, 50%);
		display: flex;
		flex-direction: row;
		width: 90%;
		height: 50%;
		justify-content: center;
		align-items: center;
		// top: 6.7rem;
	}
	
	.backArrow {
		position: absolute;
		justify-content: flex-start;
		top: 25vh;
		left: -15vw;
		cursor: pointer;
	}
}
}


@media (max-width: 844px) {

	.container {
		display: flex;
		align-items: center;
		justify-content: center;
		height: 200vh;
		width: 100vw;
	}

	.timeline {
		position: absolute;
		left: 10vw;
		height: 100vh;
		width: 5vw;
		
		// flex-direction: column;
		border-left: 0.1rem solid $light-lime;
		border-bottom: none;
		// justify-content: flex-start;
		// color: red;
		justify-content: space-evenly;
		align-items: center;
		
		
		.events {
			position: absolute;
			top: 15vh;
			// left: 50%;
			transform: translate(-50%, 50%);
			display: flex;
			flex-direction: column;
			width: 90%;
			height: 50%;
			justify-content: space-evenly;
			align-items: flex-start;
			// top: 6.7rem;
		}
		
		.backArrow {
			position: absolute;
			justify-content: flex-start;
			top: 25vh;
			left: -72vw;
			cursor: pointer;
		}
	}
}

@keyframes glow {
    0%, 100% {
        filter: url(#neon);
    }
    50% {
        filter: url(#big-neon);
    }
}

.backArrow:hover svg polygon {
    animation: glow 2s infinite;
}

@media (max-width: 845px) {
	.timeline.event-selected {
		top: -20vh;
		opacity: 1;
		// top: 30rem;
	}

	.timeline.event-deselected {
		top: 30vh;
		opacity: 0;
		// color: red;
		// top: 40rem;
	}

}

@media (min-width: 846px) {
	.timeline.event-selected {
		top: 0vh;
		// top: 30rem;
	}

	.timeline.event-deselected {
		top: 30vh;
		// top: 40rem;
	}

}