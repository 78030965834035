@import '../main.scss';

@keyframes slideLeft {
	from {
		transform: translateX(0);
		opacity: 1;
	}
	to {
		transform: translateX(-35rem);
		opacity: 1;
	}
}

@keyframes appearLeft {
	from {
		transform: translateX(30rem);
		opacity: 0;
	}
	to {
		transform: translateX(0);
		opacity: 1;
	}
}

@keyframes delayFadeIn {
	0% { opacity: 0; }
	20% { opacity: 0; }
	100% { opacity: 1; }
  }


.title:hover {
	animation: neonGlow 2s infinite;
}

.date {
	position: absolute;
	width: 100%;
	text-align: center; 
	font-size: small;
}

.verticalLine {
	position: absolute;
	text-align: center;
	height: 0.5rem;
	width: 0.1rem;
	background-color: $light-lime;
	// left: 15rem;
	// top: 3rem;
}

@keyframes slideUp {
	0% {
	  transform: translateY(100%);
	}
	100% {
	  transform: translateY(0);
	}
}

@keyframes fadeIn {
	0% {
	  opacity: 0;
	}
	100% {
	  opacity: 1;
	}
}

@media (min-width: 845px) {

	.event {
		flex-grow: 1;
		text-align: center;
		margin: 1rem;
		position: relative;
		align-items: center;
		
		.title {
			position: absolute;
			width: 100%;
			text-align: center; 

			.infoSvg {
				display: none;
			}
		}
	
		&.expanded {
			width: 80% !important;
	
			.title {
				text-align: center;
				animation: slideLeft 1s ease-in 0s 1 forwards;
				font-weight: bold;
				font-size: medium;
				cursor: pointer;
			}
	
			.verticalLine {
				// position: absolute;
				// text-align: center;
				height: 0rem;
				width: 0rem;
				background-color: $light-lime;
			}
	
			.date {
				animation: slideLeft 1s ease-in 0s 1 forwards;
				width: 100%;
				font-size: small;
	
				.location {
					// animation: delayFadeIn 15s forwards;
				}
				
			}
	
	
		}
	
	}
	

	.eventInfo {

		position: absolute;
		display: flex;
		flex-direction: row;
		// top: 5rem;
		margin-top: 5rem;
	
		.description {
			opacity: 0;
			width: 40rem;
			text-align: start;
			font-size: medium;
			animation: fadeIn 3s forwards, slideUp 3s forwards;
			margin-left: 5rem;
			line-height: 1.5;
			text-align: justify;
			text-justify: auto;
			
			span {
				color: $fresh-lemon;
			}
	
			.more {
				// background-color: red;
				margin-top: 1rem;
				width: 100%;
				height: 15rem;
				display: flex;
				flex-direction: row;
				text-align: none;
				justify-content: flex-start;
	
				.skills {
					display: flex;
					flex-direction: column;
					width: 50%;
	
					li {
						padding-left: 0.5rem;
					}
	
					li::marker {
						content: "⇛";
						color: $fresh-lemon;
						padding-left: 0.2rem;
					}
				}
	
				.links {
	
					width: 50%;
	
					ul {
						padding: 0rem;
					}
	
					a {
						position: relative;
						text-decoration: none;
						color: inherit;
						display: inline-block;
						text-transform: uppercase;
						font-size: x-small;
						// font-variant: small-caps;
					}
	
					a::after {
						content: '';
						position: absolute;
						width: 100%;
						transform: scaleX(0);
						height: 2px;
						bottom: 0;
						left: 0;
						background-color: $fresh-lemon;
						transform-origin: bottom right;
						transition: transform 0.25s ease-out;
					}
					  
					a:hover::after {
						transform: scaleX(1);
						transform-origin: bottom left;
					}
	
					li {
						list-style-type: none;
						padding-left: 0.2rem;
						// width: 8rem;
						letter-spacing: .1rem;
	
					}
	
				}
			}
		}
	
		.pictures {
			position: relative;
			width: 600px;
			  height: 400px;
			  
			img {
				animation: fadeIn 3s forwards, slideUp 3s forwards;
				max-width: 15rem;
				position: absolute;
				width: 50%;
				opacity: 0;
				transition: all 0.5s ease-in-out;
	
				--b: 1px;
				--c: #0000 25%,#abd699 0;
				padding: 10px;
				background:
					conic-gradient(from 90deg  at top    var(--b) left  var(--b),var(--c)) 0    0,
					conic-gradient(from 180deg at top    var(--b) right var(--b),var(--c)) 100% 0,
					conic-gradient(from 0deg   at bottom var(--b) left  var(--b),var(--c)) 0    100%,
					conic-gradient(from -90deg at bottom var(--b) right var(--b),var(--c)) 100% 100%;
				background-size: 50px 50px;
				background-repeat: no-repeat;
				transition: .6s;
				cursor: pointer;
			}
	
			img:hover {
				background-size: 51% 51%;
			}
				
			img:first-child {
				top: 30%;
				left: 10%;
				transform: translate(-50%, -50%);
				opacity: 1;
			}
			  
			img:nth-child(2) {
				top: 0;
				right: 0;
				opacity: 1;
			}
			  
		}
	
	}

	.academic {

		.title {
			color: $light-lime;
			bottom: 1rem;
		}
		.date {
			top: 1rem;
		}

		.verticalLine {
			left: 4.5rem;
			top: -0.5rem;
		}

		
	}

	.professional {

		.title {
			color: $light-lime;
			top: 1rem; 
		}

		.date {
			bottom: 1rem;
		}

		.verticalLine {
			left: 4.5rem;
			top: 0rem;
		}
	}
}

@media (max-width: 844px) {

	.eventInfo {

		position: absolute;
		display: flex;
		flex-direction: column;
		// top: 20rem;
		height: auto;
		width: 100vw;
		// color:red;
		// top: 10vh;
	
		.description {
			opacity: 0;
			width: 70vw;
			text-align: start;
			font-size: medium;
			animation: fadeIn 3s forwards, slideUp 3s forwards;
			margin-left: 10vw;
			line-height: 1.5;
			text-align: justify;
			text-justify: auto;
			
			span {
				color: $fresh-lemon;
			}
	
			.more {
				// background-color: red;
				margin-top: 1rem;
				width: 100%;
				height: auto;
				display: flex;
				flex-direction: column;
				text-align: none;
				justify-content: flex-start;

	
				.skills {
					display: flex;
					flex-direction: column;
					width: 100%;

	
					li {
						padding-left: 0.5rem;
					}
	
					li::marker {
						content: "⇛";
						color: $fresh-lemon;
						padding-left: 0.2rem;
					}
				}
	
				.links {
	
					width: 100%;
	
					ul {
						padding: 0rem;
					}
	
					a {
						position: relative;
						text-decoration: none;
						color: inherit;
						display: inline-block;
						text-transform: uppercase;
						font-size: x-small;
						// font-variant: small-caps;
					}
	
					a::after {
						content: '';
						position: absolute;
						width: 100%;
						transform: scaleX(0);
						height: 2px;
						bottom: 0;
						left: 0;
						background-color: $fresh-lemon;
						transform-origin: bottom right;
						transition: transform 0.25s ease-out;
					}
					  
					a:hover::after {
						transform: scaleX(1);
						transform-origin: bottom left;
					}
	
					li {
						list-style-type: none;
						padding-left: 0.2rem;
						// width: 8rem;
						letter-spacing: .1rem;
	
					}
	
				}
			}
		}
	
		.pictures {
			// position: relative;
			width: 80vw;
			height: auto;
			display: flex;
			flex-direction: column;
			align-items: center;
			// background-color: red;
			  
			img {
				margin-bottom: 2vh;
				animation: fadeIn 3s forwards, slideUp 3s forwards;
				max-width: 70vw;
				position: relative;
				width: 90%;
				opacity: 0;
				transition: all 0.5s ease-in-out;
	
				--b: 1px;
				--c: #0000 25%,#abd699 0;
				padding: 10px;
				background:
					conic-gradient(from 90deg  at top    var(--b) left  var(--b),var(--c)) 0    0,
					conic-gradient(from 180deg at top    var(--b) right var(--b),var(--c)) 100% 0,
					conic-gradient(from 0deg   at bottom var(--b) left  var(--b),var(--c)) 0    100%,
					conic-gradient(from -90deg at bottom var(--b) right var(--b),var(--c)) 100% 100%;
				background-size: 50px 50px;
				background-repeat: no-repeat;
				transition: .6s;
				cursor: pointer;
			}
	
			img:hover {
				background-size: 51% 51%;
			}
				
			img:first-child {
				// top: 30%;
				left: 10%;
				transform: translate(-50%, -50%);
				opacity: 1;
			}
			  
			img:nth-child(2) {
				// top: 0;
				// right: 0;
				left: 10%;
				opacity: 1;
			}
			  
		}
	
		
	}

	.event {
		// flex-grow: 0.5;
		margin: 0;
		position: relative;
		// align-items: center;
		display: flex;
		flex-direction: row;
		// padding-bottom: 1rem;
		// top:auto;

		
	
		.title {
			position: absolute;
			width: 75vw;
			text-align: left;
			// margin-top: 3rem;
			// color: red;
		}
	
		&.expanded {
			// width: 80% !important;

			.event {
				display: flex;
				flex-direction: column;
				position: absolute;
				// top: 5rem;
				// color:red;
			}

			// .academic {

				display: flex;
				flex-direction: column;
				// color: red;
				// top: auto;

				
				.title {
					// position: absolute;
					// top: 20vh;
					align-items: flex-start;
					justify-content: start;
					// animation: slideLeft 1s ease-in 0s 1 forwards;
					font-weight: bold;
					font-size: medium;
					cursor: pointer;
				}
				
				.verticalLine {
					// position: absolute;
					// text-align: center;
					height: 0rem;
					width: 0rem;
					background-color: $light-lime;
				}
				
				.date {
					// animation: slideLeft 1s ease-in 0s 1 forwards;
					position: absolute;
					bottom: 1rem;
					left: 5vw;
					width: 80vw;
					font-size: small;
					display: flex;
					flex-direction: column;
					align-items: flex-start;
					justify-content: flex-start;
					// justify-content:first baseline;
					
					.location {
						// animation: delayFadeIn 15s forwards;
						align-items: flex-start;
						// width: 80vw;
					}
					
				}
			// }

			// .academic {
			// 	display: flex;
			// 	flex-direction: column;

			// 	.title {
			// 		// background-color: red;
			// 		color: $light-lime;
			// 		// color: red;
			// 		// bottom: 4rem;
			// 		top: auto;
			// 		padding-left: 1rem;
			// 		font-size: small;
			// 		font-weight: 700;
					
			// 	}
				
			// 	.date {
			// 		right: 2rem;
			// 		font-size: x-small;
			// 		// position: relative;
			// 		width: 100%;
			// 		text-align: center;
			// 		align-items: center;
			// 		display: flex;
			// 		bottom: 8vh;
			// 	}
				
			// 	.verticalLine {
			// 		position: absolute;
			// 		left: 0rem;
			// 		text-align: center;
			// 		width: 0.5rem;
			// 		height: 0.1rem;
			// 		background-color: $light-lime;
			// 		top: -10vh;
			// 	}

			// }
	
	
		}

	}

	.academic {

		display: flex;
		flex-direction: column;

		.title {
			// background-color: red;
			color: $light-lime;
			// color: red;
			bottom: 4rem;
			padding-left: 1.5rem;
			font-size: small;
			font-weight: 500;
			justify-content: space-between;

			svg {
				// color: white;
				padding-left: 0.4rem;
				position: absolute;
				top: 0.2rem;
			}
			
		}
		
		.date {
			right: 1.5rem;
			font-size: x-small;
			// position: relative;
			width: 100%;
			text-align: center;
			align-items: center;
			display: flex;
			bottom: 8vh;
		}
		
		.verticalLine {
			position: absolute;
			left: 0.5rem;
			text-align: center;
			width: 0.5rem;
			height: 0.1rem;
			background-color: $light-lime;
			top: -10vh;
		}
	}

	.professional {
		.title {
			// background-color: red;
			color: $light-lime;
			// color: red;
			bottom: 4rem;
			padding-left: 1.5rem;
			font-size: small;
			font-weight: 500;
			display: inline;

			svg {
				// color: white;
				padding-left: 0.4rem;
				position: absolute;
				top: 0.2rem;
			}
			
		}
		
		.date {
			right: 1.5rem;
			font-size: x-small;
			// position: relative;
			width: 100%;
			text-align: center;
			align-items: center;
			display: flex;
			bottom: 8vh;
		}
		
		.verticalLine {
			position: absolute;
			left: 0.5rem;
			text-align: center;
			width: 0.5rem;
			height: 0.1rem;
			background-color: $light-lime;
			top: -10vh;
		}
	}
	

}

