@import '../main.scss';

.codeFragment {
	max-width: 15rem;
	width: auto;
	height: auto;
	background: #1C252C;
	filter: drop-shadow(-.1rem .1rem 0.2rem $light-lime);
	// margin-bottom: 2rem;
	align-items: center;
	padding: 1rem;
	display: inline-block;
	letter-spacing: 0.08rem;
	font-size: small;

	.special {
		color: yellow;
	}

	.title {
		color: $light-lime;
	}
	
	.value {
		color: $mint;
	}
	

	ul {
		list-style-type: none;
		padding-left: 1rem;
		margin: 0.2rem;
	}

	li {
		// display: inline-block;
		color: #eeeeee;
	}
}