@import '../main.scss';

@keyframes slideup {
	from {
		transform: translateY(0);
		opacity: 1;
	}
	to {
		transform: translateY(-200px);
		opacity: 1;
	}
  }

.active {
	animation: slideup 1s ease-in 0s 1 forwards;
}

.container {
	font-size: xx-large;
	padding-left: 15vw;
	position: relative;
	top: 15rem;
	// left: 10%;

	.title {
		color: $light-lime;
	}
}
